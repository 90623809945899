export default {
	name: "Footer",
	data: () => ({
		youtubeLink: process.env.VUE_APP_YOUTUBE,
	}),
	components: {},
	computed: {
		showFooter() {
			return this.$route.meta.showFooter;
		},
	},
};

<template>
	<div class="m-flyout" :class="{ 'is-open': showFlyout }">
		<nav class="m-flyout__content">
			<ul class="m-navigation">
				<li class="m-navigation__item">
					<router-link to="/" title="Ga naar Home" @click="toggleFlyout()"> Home </router-link>
				</li>
				<li v-for="link in headerLinks" :key="link" class="m-navigation__item">
					<!-- :class="{ active: isActiveLink(link.pathName) }" -->
					<router-link :to="{ name: link.pathName }" aria-expanded="false" @click="toggleFlyout()">
						{{ link.title }}
					</router-link>
				</li>
				<!-- <li class="m-navigation__item">
          <Language />
        </li> -->
				<li class="m-navigation__item">
					<router-link :to="{ name: 'Login' }" aria-expanded="false" @click="toggleFlyout()">
						<button class="c-button">
							<span>Log in</span>
						</button>
					</router-link>
				</li>
			</ul>
		</nav>
	</div>
</template>
<script src="./Flyout.controller.js"></script>
<style lang="scss" src="./Flyout.styles.scss"></style>

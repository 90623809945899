<template>
	<header class="o-header">
		<div class="o-header__bar">
			<router-link class="o-header__bar__logo" title="Ga naar home" :to="{ name: 'Home' }">
				<img
					class="a-logo a-logo-desktop u-xl-hide"
					src="~@/assets/images/logo.svg"
					alt="Risicoperceptietest"
				/>
				<img class="a-logo a-logo-mobile u-xl-show" src="~@/assets/images/logo.svg" alt="Risicoperceptietest" />
			</router-link>

			<div class="o-header__nav">
				<nav class="m-navigation" role="navigation">
					<ul class="m-navigation__nav u-xl-hide">
						<li v-for="link in headerLinks" :key="link" class="m-navigation__item">
							<!-- :class="{ active: isActiveLink(link.pathName) }" -->
							<router-link :to="{ name: link.pathName, hash: link.hash }" aria-expanded="false">
								{{ link.title }}
							</router-link>
						</li>
						<!-- TODO: Unhide when integrating multi language -->
						<li v-if="false" class="m-navigation__item">
							<!-- <Language /> -->
						</li>
						<li class="m-navigation__item">
							<router-link :to="{ name: 'Login' }" aria-expanded="false">
								<button class="c-button">
									<span>Log in</span>
								</button>
							</router-link>
						</li>
					</ul>
					<div class="m-navigation__actions u-xl-show">
						<Hamburger :show-flyout="showFlyout" @hamburgerClicked="toggleFlyout" />
					</div>
				</nav>
			</div>
		</div>
		<Flyout :show-flyout="showFlyout" :toggle-flyout="toggleFlyout" @hamburgerClicked="toggleFlyout" />
	</header>
</template>

<script src="./Header.controller.js"></script>
<style lang="scss" src="./Header.styles.scss"></style>

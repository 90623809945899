<template>
	<div class="c-footer" v-if="showFooter">
		<div class="o-row o-row--md">
			<div class="o-container">
				<div class="c-footer__content">
					<div class="c-footer__left">
						© 2021 Modavi - Designed and developed by <a href="https://modavi.be" target="_blank">Modavi</a>
					</div>
					<div class="c-footer__middle">
						<img src="~@/assets/images/icons/small-icon.svg" alt="Risicoperceptietest" srcset="" />
					</div>
					<div class="c-footer__right">
						<a href="mailto:info@risicoperceptie-test.be">
							<img src="~@/assets/images/icons/contact.svg" alt="Contact" srcset="" />
						</a>
						<a :href="youtubeLink">
							<img src="~@/assets/images/icons/youtube.svg" alt="Youtube" srcset="" />
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./Footer.controller.js"></script>

<style lang="scss" src="./Footer.styles.scss"></style>

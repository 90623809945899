<template>
	<div class="o-row">
		<div class="o-container">
			<div class="c-contact">
				<div class="grid">
					<div class="row row-middle">
						<div class="col-md-6">
							<div class="c-contact__left">
								<h2>Heb je een vraag?</h2>
								<p>Contacteer ons en we helpen je graag verder!</p>
								<div class="c-contact__method u-margin-top-lg">
									<img src="~@/assets/images/icons/phone.svg" alt="Phone" />
									<p>+32 47033 83 81</p>
								</div>
								<div class="c-contact__method">
									<img src="~@/assets/images/icons/mail.svg" alt="Mail" />
									<p>info@risicoperceptie-test.be</p>
								</div>
								<div class="c-contact__method">
									<img src="~@/assets/images/icons/youtube_contact.svg" alt="Youtube" />
									<p>risicoperceptie-test</p>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<img class="c-contact__image" src="~@/assets/images/contact.svg" alt="Contact" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./Contact.controller.js"></script>
<style lang="scss" src="./Contact.styles.scss"></style>

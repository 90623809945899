<template>
  <button class="c-button">
    <slot v-if="!loading" />
    <span
      v-show="loading"
      class="c-button__loader"
    ><img
      src="@/assets/images/icons/circle-notch-solid.svg"
      alt="loading"
    ></span>
  </button>
</template>

<script src="./Button.controller.js"></script>
<style lang="scss" src="./Button.styles.scss"></style>

import { Header, Footer } from "@/components";

export default {
	name: "App",
	components: {
		Header,
		Footer,
	},
	props: {},
	data: () => ({}),
};

import { Flyout, Hamburger } from "@/components";

export default {
	name: "Header",
	components: {
		Hamburger,
		Flyout,
		// Language
	},
	props: {},
	data: () => ({
		showFlyout: false,
		error: false,
	}),
	computed: {
		headerLinks() {
			return [
				{
					title: "Oefenexamens",
					anchor: "lessons",
					pathName: "Dashboard",
					show: true,
				},
				{
					title: "Instructies",
					anchor: "instructions",
					pathName: "Home",
					hash: "#instructions",
					show: true,
				},
				// {
				// 	title: "Testimonials",
				// 	anchor: "testimonials",
				// 	pathName: "Home",
				// 	show: true,
				// },
				{
					title: "Contacteer ons",
					anchor: "contact",
					pathName: "Home",
					hash: "#contact",
					show: true,
				},
			].filter((l) => l.show);
		},
	},
	methods: {
		toggleFlyout() {
			this.showFlyout = !this.showFlyout;
		},
		isActiveLink(pathName) {
			if (pathName === this.$route.name) {
				return true;
			}

			if (pathName === "Overview" && this.$route.name === "Detail") {
				return true;
			}
		},
	},
	created() {},
};

import { createRouter, createWebHistory } from "vue-router";
import { store } from "../store";

const checkAuth = () => {
	var token = JSON.parse(localStorage.getItem("token")) || null;

	if (token && token.expiry) {
		if (token.expiry - Date.now() < 0) {
			store.dispatch("account/LOGOUT");
			return false;
		}
		return true;
	}
	return false;
};

const ifNotAuthenticated = (to, from, next) => {
	if (!checkAuth()) {
		next();
		return;
	}
	next("/");
};

const ifAuthenticated = (to, from, next) => {
	if (checkAuth()) {
		next();
		return;
	}
	next("/login");
};

const logOut = (to, from, next) => {
	if (checkAuth()) {
		store.dispatch("account/LOGOUT").then(() => {
			next("/login");
		});
		window.location.reload();
	}
};

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("../views/Home/Home.vue"),
		meta: {
			showFooter: true,
		},
	},
	{
		path: "/terms-and-conditions",
		name: "TermsAndConditions",
		component: () => import("../views/TermsAndConditions/TermsAndConditions.vue"),
		meta: {
			showFooter: true,
		},
	},
	{
		path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
		name: "NotFound",
		component: () => import("../views/NotFound/NotFound.vue"),
		meta: {
			showFooter: true,
		},
	},
	// --- Pre login Account actions ---
	{
		path: "/login",
		name: "Login",
		component: () => import("../views/Account/Login/Login.vue"),
		// beforeEnter: ifNotAuthenticated,
	},
	{
		path: "/register",
		name: "Register",
		component: () => import("../views/Account/Register/Register.vue"),
		// beforeEnter: ifNotAuthenticated,
	},
	{
		path: "/forgot-password",
		name: "ForgotPassword",
		component: () => import("../views/Account/ForgotPassword/ForgotPassword.vue"),
		// beforeEnter: ifNotAuthenticated,
	},
	{
		path: "/reset-password",
		name: "ResetPassword",
		component: () => import("../views/Account/ResetPassword/ResetPassword.vue"),
	},
	// --- DASHBOARD ---
	{
		path: "/dashboard",
		name: "Dashboard",
		component: () => import("../views/Dashboard/Dashboard/Dashboard.vue"),
		// Todo: if not loggedin, go to login
	},
	{
		path: "/exam/:id",
		name: "Exam",
		component: () => import("../views/Dashboard/Exam/Exam.vue"),
		// Todo: if not loggedin, go to login
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		//https://router.vuejs.org/guide/advanced/scroll-behavior.html
		if (to.hash) {
			return {
				el: to.hash,
			};
		}
	},
});

export default router;

<template>
  <div class="app">
    <div id="nav">
      <Header />
    </div>
    <div class="content">
      <router-view />
    </div>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script src="./App.controller.js"></script>
<style lang="scss" src="./App.styles.scss"></style>

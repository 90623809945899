<template>
	<div class="o-row o-row--sm">
		<div class="o-container--lg">
			<div class="c-hero">
				<div>
					<h1>
						RISICOPERCEPTIETEST <br />
						OEFENEXAMENS
					</h1>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
						Pellentesque vitae lacus eget turpis maximus ultricies. Cras eu lacus diam.<br />
						Mauris nec ante in odio malesuada volutpat eget ut mauris. Pellentesque vel tincidunt leo.
					</p>
				</div>
				<div class="c-hero__ctas">
					<router-link :to="{ name: 'Home' }">
						<button class="c-button">
							<span>Maak een account</span>
						</button>
					</router-link>
					<button class="c-button c-button--secondary">
						<span>Over ons</span>
					</button>
				</div>
				<div class="c-hero__banner--bg"></div>
				<div class="c-hero__image">
					<img src="~@/assets/images/hero.png" alt="home" />
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./Hero.controller.js"></script>
<style lang="scss" src="./Hero.styles.scss"></style>

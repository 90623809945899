<template>
	<router-link :to="{ name: 'Exam', params: { id: exam.id } }">
		<div class="c-exam-card">
			<div v-if="exam.isLocked" class="c-exam-card--locked">
				<div class="c-exam-card--locked-content">
					<img src="~@/assets/images/icons/lock.svg" alt="" />
					<p>Dit examen is <br />vergrendeld.</p>
					<router-link class="c-exam-card--locked-cta" :to="{ name: 'Home' }">Upgrade account</router-link>
				</div>
			</div>
			<div class="c-exam-card__thumbnail">
				<img class="c-exam-card__thumbnail-img" :src="thumbnail" alt="Examen 01" />
			</div>
			<div class="c-exam-card__bottom">
				<div class="c-exam-card__subtitle">
					<span class="c-exam-card__subtitle-text">EXAMEN</span>
					<img src="~@/assets/images/icons/arrow_down.svg" alt="" />
				</div>
				<div class="c-exam-card__title">
					{{ exam.nameDutch }}
				</div>
				<div class="c-exam-card__bar" :class="{ 'c-exam-card__bar--full': exam.completedDate }"></div>
				<div class="c-exam-card__status">
					{{ statusText }}
				</div>
			</div>
		</div>
	</router-link>
</template>

<script src="./ExamCard.controller.js"></script>
<style lang="scss" src="./ExamCard.styles.scss"></style>

<template>
	<div class="c-instructions">
		<div class="o-container">
			<div class="grid">
				<div class="row">
					<div class="col-md-12">
						<class class="c-instructions__title">
							<h2>Hoe werkt het?</h2>
							<p>
								De risicoperceptietest is een verplicht onderdeel voor het behalen van het rijbewijs
								sinds 2017. Deze test bestaat uit 5 korte videofragmenten van het dagdagelijkse verkeer.
								Na elke video volgt er een vraag met 4 antwoorden, waarvan er minstens 1 tot 3 correct
								zijn. Je moet minstens een 6/10 halen om te slagen.
							</p>
						</class>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4">
						<div class="c-instructions__video"><img src="~@/assets/images/youtube.png" alt="Video" /></div>
					</div>
					<div class="col-md-4">
						<div class="c-instructions__quiz"><img src="~@/assets/images/quiz.png" alt="Quiz" /></div>
					</div>
					<div class="col-md-4">
						<div class="c-instructions__exam"><img src="~@/assets/images/exam.png" alt="Exam" /></div>
					</div>
				</div>
				<div class="row u-margin-top">
					<div class="col-md-4">
						<div class="c-instructions__video">
							<h3>VIDEO’S</h3>
							<p>Videofragmenten van het <br />dagdagelijkse verkeer.</p>
						</div>
					</div>
					<div class="col-md-4">
						<div class="c-instructions__video">
							<h3>QUIZ</h3>
							<p>Na elke video volgt er een vraag <br />met 4 mogelijke antwoorden.</p>
						</div>
					</div>
					<div class="col-md-4">
						<div class="c-instructions__video">
							<h3>EXAMEN</h3>
							<p>
								Met onze realistische <br />oefenexamens ben je volledig <br />voorbereid op je examen.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./Instructions.controller.js"></script>
<style lang="scss" src="./Instructions.styles.scss"></style>

import moment from "moment";

export default {
	name: "ExamCard",
	props: {
		exam: {
			type: Object,
		},
	},
	data: () => ({
		example: true,
	}),
	computed: {
		thumbnail() {
			try {
				return require(`@/assets/images/${this.exam.thumbnailFileName}`);
			} catch (error) {
				return require(`@/assets/images/default.png`);
			}
		},
		statusText() {
			return this.exam.completedDate
				? `Voltooid • ${moment(this.exam.completedDate).locale("nl").format("DD MMMM YYYY")}`
				: "Nog niet voltooid";
		},
	},
	created() {
		document.body.style.backgroundColor = "#F9FAFB";
	},
	beforeUnmount() {
		document.body.style.backgroundColor = "unset";
	},
};
